import React from 'react';

const Leagues = () => {
  return (
    <div className="section">
      <h2>Leagues</h2>
      <p>Website Under Construction</p>
      <p>Check SilverBallSacramento.com for league information</p>
    </div>
  );
};

export default Leagues;
