import React from 'react';

const Pictures = () => {
  return (
    <div className="section">
      <h2>Gallery</h2>
      
      <p>Website Under Construction</p>
      <p>Check Back Later for Event Photos</p>
    </div>
  );
};

export default Pictures;
