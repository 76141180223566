// src/components/Alerts.js

import React, { useState } from 'react';
import './Alerts.css';
import MessageModal from './MessageModal'; // Import the MessageModal component

const Alerts = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    smsOptIn: false,
  });

  const [errors, setErrors] = useState({});
  const [modalMessage, setModalMessage] = useState(''); // State to hold the modal message
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, '');

    if (phoneNumber.length < 4) {
      return phoneNumber;
    }

    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setFormData({
      ...formData,
      phone: formattedPhoneNumber,
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      smsOptIn: e.target.checked, // Update the smsOptIn value based on checkbox
    });
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrors({});

    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!validatePhoneNumber(formData.phone)) {
      newErrors.phone = 'Please enter a valid phone number';
    }
    if (!formData.smsOptIn) {
      newErrors.smsOptIn = 'You must agree to receive SMS alerts';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const response = await fetch('https://users.everyonespinballclub.workers.dev/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    const result = await response.json();
    console.log(result);
    // Set the modal message based on the API response
    if (result.success=== false) {
      if (result.message === 'This phone number is already registered.') {
        setModalMessage('This phone number is already registered. You are already signed up for alerts!');
      } else {
        setModalMessage('There was an error. Please try again.');
      }
    } else if (result.success === true) {
      setModalMessage('Thank you for signing up! You will now receive updates about Sacramento pinball events.');
      setFormData({ firstName: '', lastName: '', phone: '', smsOptIn: false });
    }

    // Open the modal
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className="alerts-container">
      <h1>Sign Up for Pinball Event Alerts</h1>
      <p>Stay informed about the latest Sacramento pinball events by signing up for our text alerts. Simply enter your details below to receive updates.</p>
      
      <form id="signupForm" onSubmit={handleSubmit}>
        <label htmlFor="firstName">
          First Name <span className="required">*</span>
        </label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          placeholder="Enter your first name"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
        {errors.firstName && <p className="error">{errors.firstName}</p>}

        <label htmlFor="lastName">
          Last Name <span className="required">*</span>
        </label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          placeholder="Enter your last name"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
        {errors.lastName && <p className="error">{errors.lastName}</p>}

        <label htmlFor="phone">
          Phone Number <span className="required">*</span>
        </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="Enter your phone number"
          value={formData.phone}
          onChange={handlePhoneChange}
          required
        />
        {errors.phone && <p className="error">{errors.phone}</p>}

        <label className="smsOptInLabel">
          <input
            type="checkbox"
            name="smsOptIn"
            checked={formData.smsOptIn}
            onChange={handleCheckboxChange}
            required
          />
          I would like to receive SMS alerts <span className="required">*</span>
        </label>
        {errors.smsOptIn && <p className="error">{errors.smsOptIn}</p>}

        <button type="submit">Sign Up</button>

        <p className="required-note"><span className="required">*</span> required</p>
      </form>

      <MessageModal
        show={isModalOpen}
        onClose={closeModal}
        message={modalMessage}
      />
    </div>
  );
};

export default Alerts;
