// src/components/MessageModal.js

import React from 'react';
import './MessageModal.css';

const MessageModal = ({ show, onClose, message }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="message-modal-overlay" onClick={onClose}>
      <div className="message-modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="message-close-button" onClick={onClose}>&times;</span>
        <div className="message-modal-body">
          <p>{message}</p>
        </div>
        <button className="message-modal-close-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default MessageModal;
