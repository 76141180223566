import React from 'react';

const About = () => {
  return (
    <div className="section">
      <h2>About Us</h2>
      <p>We are passionate about pinball and dedicated to bringing you the best pinball experience.</p>
    </div>
  );
};

export default About;
