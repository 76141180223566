import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import GameList from './components/GameList';
import Tournaments from './components/Tournaments';
import Leagues from './components/Leagues';
import Pictures from './components/Pictures';
import Alerts from './components/Alerts';  // Import the Alerts component
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/games" element={<GameList />} />
          <Route path="/tournaments" element={<Tournaments />} />
          <Route path="/leagues" element={<Leagues />} />
          <Route path="/pictures" element={<Pictures />} />
          <Route path="/alerts" element={<Alerts />} />
          {/*<Route path="/contact" element={<Contact />} />*/}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
