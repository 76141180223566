import React from 'react';

const Tournaments = () => {
  return (
    <div className="section">
      <h2>Tournaments</h2>
      <p>Website Under Construction</p>
      <p>Check SilverBallSacramento.com for tournament information</p>
    </div>
  );
};

export default Tournaments;
