import React, { useEffect, useState } from 'react';
import './Modal.css';

const Modal = ({ show, onClose, machine }) => {
  const [tips, setTips] = useState([]);
  const [instructionCardUrls, setInstructionCardUrls] = useState([]);
  const [manualUrls, setManualUrls] = useState([]);
  const [partsUrls, setPartsUrls] = useState([]);
  const [showAllTips, setShowAllTips] = useState(false);

  useEffect(() => {
    const fetchPinTips = async () => {
      if (!machine) return;

      const url = new URL('https://app.matchplay.events/api/pintips');
      const params = { opdbId: machine.opdb_id };
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

      const headers = {
        'Authorization': 'Bearer 307|6ZI7FPjh3aVC8UnEY6ZW1N0Xx5QcNXJXYzzzdRoOa8d72196',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: headers,
        });
        const data = await response.json();
        setTips(data.pintips || []);
      } catch (error) {
        console.error('Error fetching PinTips:', error);
      }
    };

    const fetchIpdbLinks = async (filterString, setFunction) => {
      if (!machine.ipdb_id) return;

      try {
        const response = await fetch(`https://ipdb.everyonespinballclub.workers.dev/?ipdbId=${machine.ipdb_id}&filterString=${filterString}`);
        const data = await response.json();
        const formattedData = data.map(url => {
          const filename = url.split('/').pop().replace(/_/g, ' '); // Replace underscores with spaces
          return { url, filename };
        });
        setFunction(formattedData);
      } catch (error) {
        console.error(`Error fetching ${filterString} links:`, error);
        setFunction([]);
      }
    };

    const fetchAllIpdbLinks = async () => {
      if (!machine) return;

      fetchIpdbLinks('instruction', setInstructionCardUrls);
      fetchIpdbLinks('manual', setManualUrls);
      fetchIpdbLinks('parts', setPartsUrls);
    };

    setTips([]);
    setInstructionCardUrls([]);
    setManualUrls([]);
    setPartsUrls([]);
    fetchPinTips();
    fetchAllIpdbLinks();
  }, [machine]);

  if (!show || !machine) {
    return null;
  }

  const displayedTips = showAllTips ? tips : tips.slice(0, 3); // Show only 3 tips initially

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <img src={machine.image} alt={machine.name} className="modal-image" />
        <div className="modal-info">
          <h2>{machine.name}</h2>
          <p><strong>Manufacturer:</strong> {machine.manufacturer}</p>
          <p><strong>Year:</strong> {machine.year}</p>
          <a href={machine.ipdb_link} target="_blank" rel="noopener noreferrer">View on IPDB</a>
          
          <div className="additional-links">
            {instructionCardUrls.length > 0 && (
              <div>
                <h3>Instruction Card</h3>
                {instructionCardUrls.map(({ url, filename }) => (
                  <div key={url}>
                    <a href={url} target="_blank" rel="noopener noreferrer">{filename}</a>
                  </div>
                ))}
              </div>
            )}

            {manualUrls.length > 0 && (
              <div>
                <h3>Manuals</h3>
                {manualUrls.map(({ url, filename }) => (
                  <div key={url}>
                    <a href={url} target="_blank" rel="noopener noreferrer">{filename}</a>
                  </div>
                ))}
              </div>
            )}

            {partsUrls.length > 0 && (
              <div>
                <h3>Parts List</h3>
                {partsUrls.map(({ url, filename }) => (
                  <div key={url}>
                    <a href={url} target="_blank" rel="noopener noreferrer">{filename}</a>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="pintips">
            <h3>PinTips:</h3>
            <div className="pintips-content">
              {displayedTips.length > 0 ? (
                <ul>
                  {displayedTips.map((tip, index) => (
                    <li key={index}>
                      <p>{tip.text}</p>
                      <p><strong>Votes:</strong> {tip.voteTotal}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No tips available.</p>
              )}
              {tips.length > 3 && !showAllTips && (
                <div className="show-more" onClick={() => setShowAllTips(true)}>
                  Show more
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
