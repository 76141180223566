import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import './Games.css'; // Ensure this file exists and contains relevant styles

const GameList = () => {
  const [machines, setMachines] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedMachine, setSelectedMachine] = useState(null);

  // Config option: 'opdb', 'ipdb', or 'both'
  const imageSource = 'both';

  useEffect(() => {
    const fetchMachineDetails = async () => {
      try {
        const response = await fetch('https://pinballmap.com/api/v1/locations/23022/machine_details.json');
        const data = await response.json();
        const machines = data.machines;

        // Fetch additional details from OPDB API for each machine
        const machineDetails = await Promise.all(
          machines.map(async (machine) => {
            let image = '';

            if (imageSource === 'opdb' || imageSource === 'both') {
              try {
                const opdbResponse = await fetch(`https://opdb.org/api/machines/${machine.opdb_id}?api_token=SvlXTrUxDZQ2avPvToaEEawlI5DCcGvroctlsKXUIsRNnbPq8iDJRFnwJFQL`);
                const opdbData = await opdbResponse.json();
                image = opdbData.images?.[0]?.urls?.large || '';
              } catch (error) {
                console.error(`Error fetching from OPDB for ${machine.name}:`, error);
              }
            }

            if (!image && (imageSource === 'ipdb' || imageSource === 'both')) {
              // Construct the image URL using the IPDB ID
              if (machine.ipdb_id) {
                image = `https://www.ipdb.org/images/${machine.ipdb_id}/image-1.png`;
              }
            }

            if (!image) {
              // Use a placeholder image if no image is found
              image = 'https://via.placeholder.com/640x472?text=No+Image+Available';
            }

            return {
              ...machine,
              image,
            };
          })
        );

        // Sort the machines by manufacturer
        machineDetails.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer));

        setMachines(machineDetails);
        setCount(machineDetails.length);
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };

    fetchMachineDetails();
  }, [imageSource]);

  const openModal = (machine) => {
    setSelectedMachine(machine);
  };

  const closeModal = () => {
    setSelectedMachine(null);
  };

  return (
    <div className="machine-list-container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2 className="machine-count">{count} Pinball Machines</h2>
          <div className="machine-grid">
            {machines.map((machine) => (
              <div key={machine.id} className="machine-tile" onClick={() => openModal(machine)}>
                {machine.image ? (
                  <img src={machine.image} alt={machine.name} className="machine-image" />
                ) : (
                  <div className="no-image">No Image Available</div>
                )}
                <div className="machine-info">
                  <h3>{machine.name}</h3>
                  <p>{machine.manufacturer} ({machine.year})</p>
                </div>
              </div>
            ))}
          </div>
          <p id="source">Results provided live by pinballmap.com API</p>
        </>
      )}
      <Modal show={!!selectedMachine} onClose={closeModal} machine={selectedMachine} />
    </div>
  );
};

export default GameList;
